/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, CollapseProps, Flex, List, Popconfirm, Row, Space, Tooltip, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { App } from "src/types";
import noActions from "src/shared/images/noActions.png";

import { CallSetupModal, MoveProspectsSelectStepModal, SelectEmailModal } from "src/components/pages/campaigns/CampaignDetails/ui";
import { Collapse, ContentCard, CustomSelect, Loader } from "src/components/ui";
import { getActionButtonTitle, getActionItemButtonTitle } from "src/helpers";
import { SvgIcon } from "src/shared/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.less";
import { outreachCampaignSelector, setOpenCampSettings, setOpenVariantFromAction, setitemIdSel } from "src/store/outreach-campaigns";
import { useDispatch, useSelector } from "react-redux";
import { outreachEmailsSelector, resetOutcome as resOutcomeEm } from "src/store/outreach-emails";
import { ManageEmailDrawer } from "src/components/pages/emails-data";
import { approveSteps, getFilteredLeadsRst, resetCampIdDiscEmails, viewMoreProsInStep } from "src/store/outreach-campaigns/asyncRequests";
import { addEmailBe } from "src/store/outreach-emails/asyncRequests";
import { ViewAiEmailModal } from "./modals/ViewAiEmailModal";
import { CampApproveModal } from "./modals/CampApproveModal";
import { userIsTrialSelector } from "src/store/user";

interface ActionsForTodayProps {
  data: App.AllAction[];
  loading: boolean;
  payingClient: boolean;
  frmtdSeq: any[];
}

export const ActionsForTodayCard: FC<ActionsForTodayProps> = ({ loading, payingClient, frmtdSeq }) => {
  const dispatch = useDispatch();
  const { outreachCampaignsList, campaignDisplaying, eachStepsAnalytics, itemIdSel, loadingMorePros, openSettingsModal } =
    useSelector(outreachCampaignSelector);
  console.log({ campaignDisplaying, eachStepsAnalytics }, "asdfjklnasjklfn");
  // const [randomImgSel, setRandomImgSel] = useState();

  // useEffect(() => {
  //   setRandomImgSel(Math.random() >= 0.5);
  // }, []);

  const [openModal, setOpenModal] = useState(false);
  const [openSelectStepModal, setOpenSelectStepModal] = useState(false);
  const [openCallSetupModal, setOpenCallSetupModal] = useState(false);
  const [selectedCounts, setSelectedCounts] = useState<{ [key: string]: number }>({});
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const [visibleItems, setVisibleItems] = useState<number>(7);
  // const [isPlanActivated, setIsPlanActivated] = useState(true)
  const navigate = useNavigate();

  // const [totalEmails, setTotalEmails] = useState<number>(0)
  const [totalAutoEmails, setTotalAutoEmails] = useState<number>(0);
  const [totalNoAutoEmails, setTotalNoAutoEmails] = useState<number>(0);
  const [totalPhones, setTotalPhones] = useState<number>(0);
  // const [prospectsToSee, setProspectsToSee] = useState([])
  const [actionsPerStep, setActionsPerStep] = useState<{ stepNum: number; actions: number }>([]);

  const checkTypeOfStep = (stepNum) => {
    return campaignDisplaying?.campaign?.campaign_steps?.find((step) => step?.step_number === stepNum)?.step_type;
  };
  const checkIsAutoContinuationOfStep = (stepNum) => {
    return campaignDisplaying?.campaign?.campaign_steps?.find((step) => step?.step_number === stepNum)?.auto_start;
  };
  //   const checkAnalyticsWithStepId = (stepId) =>{
  // return campaignDisplaying?.campaign?.campaign_steps?.find((step)=>step?.id===stepId)?.step_number
  //   }

  const getStepDetailsId = (stepId) => {
    return campaignDisplaying?.campaign?.campaign_steps?.find((step) => step?.id === stepId);
  };
  const [aiEmailId, setAiEmailId] = useState(null);
  const [campDisplaying, setCampDisplaying] = useState([]);
  const [emailStepsOptions, setEmailStepsOptions] = useState([]);
  const [phoneStepsOptions, setPhoneStepsOptions] = useState([]);

  useEffect(() => {
    // let totEmail=0
    let totAutoEmail = 0;
    let totNoAutoEmail = 0;
    let totPhone = 0;
    let actPerStep = [];
    for (let stepNum in eachStepsAnalytics) {
      stepNum = +stepNum;
      if (eachStepsAnalytics.hasOwnProperty(stepNum)) {
        const actions = eachStepsAnalytics[stepNum].prospectReadyStepCount || 0;
        actPerStep = [...actPerStep, { stepNum, actions }];
        const isPhoneStep = checkTypeOfStep(stepNum) === "phonecall"; // later need to add SMS and task logic
        if (isPhoneStep) {
          totPhone += actions;
        } else {
          const isAutoEm = !!checkIsAutoContinuationOfStep(stepNum);
          if (isAutoEm) {
            totAutoEmail += actions;
          } else {
            totNoAutoEmail += actions;
          }
          // totEmail  += actions
        }
      }

      const emStepOptions = [{ value: "None", label: "No Step Selected" }];
      const phoneStepOptions = [{ value: "None", label: "No Step Selected" }];
      const campSteps = campaignDisplaying?.campaign?.campaign_steps || [];
      if (campSteps?.length) {
        // console.log(campSteps,'campSteps');
        //  for (let step of campSteps) {
        for (let step of frmtdSeq) {
          // const type = checkTypeOfStep(step.step_number)
          const actionsInStep = actPerStep?.find((st) => st.stepNum === step.stepNum)?.actions;
          console.log("actionsInStep", step);
          if (step?.type === "aiemail") {
            setAiEmailId(step.id);
          }
          if (step?.type === "phonecall") {
            phoneStepOptions.push({
              label: `Step ${step.stepNum} ${actionsInStep ? `- ${actionsInStep} calls, start power dialer` : `- No prospects are in this step now`}`,
              value: step.id,
            });
          } else {
            emStepOptions.push({
              label: `Step ${step.stepNum} ${
                actionsInStep
                  ? `- ${actionsInStep} emails ${step.autoStart ? `in queue, no action required` : ", take action."}`
                  : `- No prospects are in this step now`
              }`,
              value: step.id,
            }); // TODOF add a check if email add is not connected write to take action! QUICKLY DO THIS!!
          }
        }
      }
      // console.log(emStepOptions,'emStepOptions');
      setEmailStepsOptions(emStepOptions);
      setPhoneStepsOptions(phoneStepOptions);
      setActionsPerStep(actPerStep);
      setTotalPhones(totPhone);
      // setTotalEmails(totEmail)
      setTotalAutoEmails(totAutoEmail);
      setTotalNoAutoEmails(totNoAutoEmail);
    }
  }, [campaignDisplaying, eachStepsAnalytics, frmtdSeq]);

  // useEffect(() => {
  //     const emStepOptions = []
  //     const phoneStepOptions = []
  //     const campSteps = campaignDisplaying?.campaign?.campaign_steps || []
  //     if(campSteps?.length){
  //    for (let step in campSteps) {
  //       const type = checkTypeOfStep(step.step_number)
  //       if(type==='phonecall'){
  //         phoneStepOptions.push({label:`Step ${step.step_number} - ${}`})
  //       }
  //    }
  // }
  // }, [campaignDisplaying]);

  const onLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 7);
  };

  const handleUpdatePlanClick = () => {
    navigate("/settings");
  };

  const loadMoreBtn = (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button className={"link"} onClick={onLoadMore}>
        See more
      </Button>
    </div>
  );
  const campRecord = outreachCampaignsList?.find((oc) => oc?.overview?.campId === campaignDisplaying?.campaign?.id);

  const showManSendsModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const showSelectStepModalModal = () => {
    setOpenSelectStepModal(true);
  };

  const showCallSetupModal = () => {
    setOpenCallSetupModal(true);
  };

  const onCancel = () => {
    setOpenModal(false);
    setOpenCallSetupModal(false);
    setOpenSelectStepModal(false);
  };

  // const handleCheckboxChange = (itemTitle: string, checked: boolean, action: string | undefined) => {
  //   setSelectedCounts(prevCounts => ({
  //     ...prevCounts,
  //     [itemTitle]: checked ? (prevCounts[itemTitle] || 0) + 1 : (prevCounts[itemTitle] || 0) - 1,
  //   }))
  //   if (checked && action) {
  //     setSelectedActions(prevSelectedActions => [...prevSelectedActions, action])
  //   } else {
  //     setSelectedActions(prevSelectedActions => prevSelectedActions.filter(item => item !== action))
  //   }
  // }

  // const totalInfoCount = data.reduce((total, action) => total + action.info.length, 0)

  // const title = (
  //   <Row style={{ gap: 16 }}>
  //     <Typography.Paragraph>
  //       {`${selectedActions.length} of ${totalInfoCount} ${
  //         selectedActions.length === 1 ? "prospect is selected" : "prospects are selected"
  //       }`}
  //     </Typography.Paragraph>
  //     <Button
  //       className={"link"}
  //       onClick={() => {
  //         setSelectedActions([])
  //         setSelectedCounts({})
  //       }}
  //     >
  //       Deselect all
  //     </Button>
  //   </Row>

  // )

  const [aiEmailOpen, setAiEmailOpen] = useState({});
  const [controlActKeys, setControlActKeys] = useState([]);

  const [emValIdToSee, setEmValIdToSee] = useState("None");
  const [phValIdToSee, setPhValIdToSee] = useState("None");

  useEffect(() => {
    console.log(controlActKeys, "controlActKeys");
    if (!controlActKeys?.length) {
      dispatch(setitemIdSel(null));
      setEmValIdToSee("None");
      setPhValIdToSee("None");
    } else {
      const isEmail = !!emailStepsOptions?.find((em) => em.value === itemIdSel);
      console.log(isEmail, controlActKeys[0], "asdfkjn");
      if (controlActKeys[0] === "em" && !isEmail) {
        setPhValIdToSee("None");
        dispatch(setitemIdSel(null));
      } else if (controlActKeys[0] === "ph" && isEmail) {
        setEmValIdToSee("None");
        dispatch(setitemIdSel(null));
      }
    }
  }, [controlActKeys]);

  const handleChangeEmToSee = (newVal) => {
    // setEmValIdToSee(newVal)
    dispatch(setitemIdSel(newVal));
  };
  const handleChangePhToSee = (newVal) => {
    // setPhValIdToSee(newVal)
    dispatch(setitemIdSel(newVal));
  };

  useEffect(() => {
    // console.log(itemIdSel,'isEmail');
    if (itemIdSel) {
      const isEmail = !!emailStepsOptions?.find((em) => em.value === itemIdSel);
      // console.log(itemIdSel,isEmail,'isEmail');
      if (isEmail) {
        setEmValIdToSee(itemIdSel);
        setControlActKeys(["em"]);
        setPhValIdToSee("None");
      } else {
        setPhValIdToSee(itemIdSel);
        setControlActKeys(["ph"]);
        setEmValIdToSee("None");
      }
    }
    // else{
    //   setControlActKeys([])
    //   setPhValIdToSee('None')
    //       setEmValIdToSee('None')
    //     }
  }, [itemIdSel]);

  // const {additionalEmails} = useSelector(outreachEmailsSelector)

  //  TODO remove phone or email if its not in there and if nothign so write it.
  const idSel = emValIdToSee !== "None" ? emValIdToSee : phValIdToSee !== "None" ? phValIdToSee : null;
  const stepDetails = idSel && getStepDetailsId(idSel);
  const selectedStepNum = stepDetails && stepDetails?.step_number;
  const prospectsInStep = selectedStepNum && eachStepsAnalytics[selectedStepNum];
  const readyPros = prospectsInStep?.firstTenProsReady;
  const notReadyPros = prospectsInStep?.firstTenProsNotReadyYet;

  console.log(prospectsInStep, "selectedStepNum");

  const handleViewMore = () => {
    const moreProsAmnt = Math.ceil(readyPros?.length / 10) * 10 + 10;
    dispatch(viewMoreProsInStep({ campId: campaignDisplaying?.campaign?.id, moreProsAmnt, stepNum: selectedStepNum }));
  };
  const approveEmails = (stepToApprove) => {
    dispatch(approveSteps({ campId: campaignDisplaying?.campaign?.id, stepToApprove }));
  };
  console.log();
  const items: CollapseProps["items"] = [
    emailStepsOptions?.length > 1 && {
      key: "em",
      label: (
        <>
          In Email Step:{" "}
          {totalAutoEmails + totalNoAutoEmails
            ? `${totalAutoEmails + totalNoAutoEmails} prospect${totalAutoEmails + totalNoAutoEmails !== 1 ? "s" : ""}${
                totalNoAutoEmails ? `, take action` : !campRecord?.overview?.isActive ? `, activate campaign to proceed` : ", no action required"
              }`
            : "All completed"}
        </>
      ),
      extra: (
        <Button className={"link"} onClick={() => (totalNoAutoEmails ? approveEmails("all") : showAddSetUpModal())} style={{ marginLeft: "15px" }}>
          {totalNoAutoEmails ? `Approve steps & send` : `Manage emails`}
        </Button>
      ),
      children: (
        <>
          {/* emailStepsOptions
phoneStepsOptions */}
          <CustomSelect options={emailStepsOptions} onChange={handleChangeEmToSee} value={emValIdToSee} style={{ width: "95%", marginTop: "-10px" }} />
          {!!readyPros?.length && (
            <>
              <br />
              <br />
              <Flex justify="space-between" style={{ width: "95%", margin: "0 auto" }}>
                <div>{stepDetails?.auto_start ? `Prospects in queue for ${aiEmailId === idSel ? "Ai" : "templated"} step:` : `Prospects ready for step:`}</div>
                <div>
                  {aiEmailId !== idSel && (
                    <Button
                      className="link"
                      style={{ marginLeft: "auto", marginRight: "6px" }}
                      onClick={() => {
                        dispatch(setOpenVariantFromAction(true));
                      }}
                    >
                      View Email Variations
                    </Button>
                  )}
                  {!stepDetails?.auto_start && (
                    <Button className="link" style={{ marginLeft: "auto" }} onClick={() => approveEmails(selectedStepNum)}>
                      Approve Step
                    </Button>
                  )}
                </div>
              </Flex>
            </>
          )}
          {readyPros?.length ? (
            <div style={{ padding: "10px" }}>
              {readyPros?.map((pros) => (
                <Space
                  key={pros.id}
                  // direction="vertical"
                  size={12}
                  style={{
                    padding: "10px",
                    border: "1px solid #eaeaea",
                    borderRadius: "8px",
                    marginBottom: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.Paragraph className="contact-name" style={{ marginBottom: 0 }}>
                    <div>
                      <span style={{ fontWeight: 500 }}>Name:</span> {pros.sales_prospect?.first_name || ""} {pros.sales_prospect?.last_name || ""}
                    </div>
                    {pros.sales_prospect?.title && (
                      <div>
                        <span style={{ fontWeight: 500 }}>Title:</span> {pros.sales_prospect?.title}
                      </div>
                    )}
                    {pros.sales_prospect?.company_name && (
                      <div>
                        <span style={{ fontWeight: 500 }}>Company Name:</span> {pros.sales_prospect?.company_name}
                      </div>
                    )}
                  </Typography.Paragraph>
                  {aiEmailId === idSel && (
                    <Button
                      className="link"
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        setAiEmailOpen({ pros, isAuto: stepDetails?.auto_start });
                      }}
                    >
                      View Ai Email
                    </Button>
                  )}
                </Space>
              ))}
              <div style={{ width: "fit-content", marginLeft: "auto", marginRight: "auto", display: "flex" }}>
                <div>
                  Displaying {readyPros?.length} out of {prospectsInStep?.prospectReadyStepCount?.toLocaleString()}{" "}
                </div>{" "}
                {readyPros?.length < prospectsInStep?.prospectReadyStepCount ? (
                  <div style={{ marginLeft: "10px", display: "flex", color: "grey !important" }}>
                    {" "}
                    -{" "}
                    <Button className="link" style={{ pointerEvents: loadingMorePros ? "none" : "auto" }} onClick={() => !loadingMorePros && handleViewMore()}>
                      View More
                    </Button>
                    {loadingMorePros && <Loader size="small" style={{ marginLeft: "20px" }} />}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : emValIdToSee === "None" ? (
            ""
          ) : (
            <>
              <br />
              <br />
              <Typography.Paragraph className={"contact-name"}>
                <Flex justify="space-between">
                  <div>No prospects are in this step now </div>
                  <div>
                    {aiEmailId !== idSel && (
                      <Button
                        className="link"
                        style={{ marginLeft: "auto", marginRight: "6px" }}
                        onClick={() => {
                          dispatch(setOpenVariantFromAction(true));
                        }}
                      >
                        View Email Variations
                      </Button>
                    )}
                    {!stepDetails?.auto_start && (
                      <Button className="link" style={{ marginLeft: "auto" }}>
                        Approve Step
                      </Button>
                    )}
                  </div>
                </Flex>
              </Typography.Paragraph>
            </>
          )}
        </>
      ),
    },
    phoneStepsOptions?.length > 1 && {
      key: "ph",
      label: <>In Phone Call Step: {totalPhones ? `${totalPhones} prospect${totalPhones !== 1 && "s"}` : "All completed"}</>,
      extra: (
        <Button
          className={"link"}
          // onClick={ showModal}
        >
          Activate power dialer
        </Button>
      ),
      children: (
        <>
          <CustomSelect options={phoneStepsOptions} onChange={handleChangePhToSee} value={phValIdToSee} style={{ width: "95%", marginTop: "-10px" }} />
          {!!readyPros?.length && (
            <>
              <br />
              <br />
              <span>{stepDetails?.auto_start ? `Prospects in queue for ${aiEmailId === idSel ? "Ai" : "templated"} step:` : `Prospects ready for step:`}</span>
            </>
          )}
          {readyPros?.length ? (
            <div style={{ padding: "10px" }}>
              {readyPros?.map((pros) => (
                <Space
                  key={pros.id}
                  // direction="vertical"
                  size={12}
                  style={{
                    padding: "10px",
                    border: "1px solid #eaeaea",
                    borderRadius: "8px",
                    marginBottom: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.Paragraph className="contact-name" style={{ marginBottom: 0 }}>
                    <div>
                      <span style={{ fontWeight: 500 }}>Name:</span> {pros.sales_prospect?.first_name || ""} {pros.sales_prospect?.last_name || ""}
                    </div>
                    {pros.sales_prospect?.title && (
                      <div>
                        <span style={{ fontWeight: 500 }}>Title:</span> {pros.sales_prospect?.title}
                      </div>
                    )}
                    {pros.sales_prospect?.company_name && (
                      <div>
                        <span style={{ fontWeight: 500 }}>Company Name:</span> {pros.sales_prospect?.company_name}
                      </div>
                    )}
                  </Typography.Paragraph>
                  {/* {aiEmailId===idSel && <Button className="link" style={{ marginLeft: 'auto' }}>View Ai Email</Button>} TODOF later add call 1 person */}
                </Space>
              ))}
              <div style={{ width: "fit-content", marginLeft: "auto", marginRight: "auto", display: "flex" }}>
                <div>
                  Displaying {readyPros?.length} out of {prospectsInStep?.prospectReadyStepCount?.toLocaleString()}{" "}
                </div>{" "}
                {readyPros?.length < prospectsInStep?.prospectReadyStepCount ? (
                  <div style={{ marginLeft: "10px", display: "flex", color: "grey !important" }}>
                    {" "}
                    -{" "}
                    <Button className="link" style={{ pointerEvents: loadingMorePros ? "none" : "auto" }} onClick={() => !loadingMorePros && handleViewMore()}>
                      View More
                    </Button>
                    {loadingMorePros && <Loader size="small" style={{ marginLeft: "20px" }} />}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : phValIdToSee === "None" ? (
            ""
          ) : (
            <>
              <br />
              <br />
              <Typography.Paragraph className={"contact-name"}>No prospects are in this step now</Typography.Paragraph>
            </>
          )}
        </>
      ),
    },
  ]?.filter(Boolean);

  console.log(items, "controlActKeys", controlActKeys);

  // const items: CollapseProps["items"] = [].map((item, index) => {
  //   const selectedCount = selectedCounts[item.title] || 0
  //   const label = selectedCount !== undefined && selectedCount !== 0 ? `${item.title} (${selectedCount})` : item.title

  //   return {
  //     key: item.id,
  //     label: label,
  //     extra: (
  //       <Button
  //         key={`${index}-${item.id}`}
  //         className={"link"}
  //         onClick={getActionButtonTitle(item.title) === "Send All" ? showModal : showCallSetupModal}
  //       >
  //         {getActionButtonTitle(item.title)}
  //       </Button>
  //     ),
  //     children: (
  //       <List
  //         key={`${index}-${item.id}`}
  //         itemLayout={"horizontal"}
  //         loadMore={loadMoreBtn}
  //         dataSource={item.info.slice(0, visibleItems)}
  //         renderItem={(it: any, index) => (
  //           <List.Item
  //             key={`${index}-${it.id}`}
  //             actions={[<Button key={1} className={"link"} onClick={() => (item.title === "Emails" || item.title === "SMS" ? showModal() : closeModal())}>{getActionItemButtonTitle(item.title)}</Button>]}
  //           >
  //             <Space direction={"vertical"} size={4}>
  //               {item.title === "Tasks" ? (
  //                 <Typography.Paragraph>{it.name}</Typography.Paragraph>
  //               ) : (
  //                 <>
  //                   <Popconfirm
  //                     title={title}
  //                     placement={"topLeft"}
  //                     showCancel={false}
  //                     style={{ width: 528 }}
  //                     okType={"link"}
  //                     okText={"Move"}
  //                     onConfirm={showSelectStepModalModal}
  //                     icon={false}
  //                   >
  //                     <Checkbox
  //                       checked={selectedActions.includes(it.id)}
  //                       onChange={e => handleCheckboxChange(item.title, e.target.checked, it.id)}
  //                     >
  //                       <Typography.Paragraph className={"contact-name"}>{it.name}</Typography.Paragraph>
  //                     </Checkbox>
  //                   </Popconfirm>
  //                   <Typography.Paragraph className={"contact-data"}>{it.contact}</Typography.Paragraph>
  //                 </>
  //               )}
  //             </Space>
  //         </List.Item>
  //         )}
  //       ></List>
  //     ),
  //   }
  // })

  const location = useLocation();

  const { successfullUpdateMsg: succMsg, failedUpdateMsg: failMsg, infoUpdateMsg: infoMsg } = useSelector(outreachEmailsSelector);

  //  for when reconnecting emails this is necessary to show whats happening
  useEffect(() => {
    localStorage.removeItem("latestMeetzPg");

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => typeof prop === "string" && searchParams.get(prop),
    });
    const err = params?.error;
    const email = params?.em;
    const id = params?.id;
    const type = params?.type;
    const iframe = params?.iframe;
    if (err) {
      // push('/ai_email_campaign');
      // dispatch(emailErr({ email, type }));
      message.destroy();
      message.error(
        `Error integrating your ${
          type === "google" ? "Google" : "Outlook"
        } email. Make sure that you accepted the necessary permissions. Try to connect to it again soon.`
      );
    } else if (email && id) {
      dispatch(addEmailBe({ id, email }));
      message.destroy();
      message.info("Adding your email address now...");

      if (iframe === "true") {
        const jwt = localStorage.getItem("token");
        if (jwt) {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz?appTokenName=token&appTokenValue=${jwt}`;
        } else {
          window.location.href = `https://marketing.deal.ai/apps/app/meetz`;
        }
      }
      window.history.pushState({}, "", "/campaigns");
      setOpenDrawer(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (succMsg) {
      message.destroy();
      message.success(succMsg);
      dispatch(resOutcomeEm());
    }
  }, [succMsg]);
  useEffect(() => {
    if (failMsg) {
      message.destroy();
      message.error(failMsg);
      dispatch(resOutcomeEm());
    }
  }, [failMsg]);
  useEffect(() => {
    if (infoMsg) {
      message.destroy();
      message.info(infoMsg);
      dispatch(resOutcomeEm());
    }
  }, [infoMsg]);

  const trialClient = useSelector(userIsTrialSelector);
  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClsDrawer = () => {
    setOpenDrawer(false);
  };

  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [tabAppModal, setTabAppModal] = useState("app");

  useEffect(() => {
    if (openSettingsModal) {
      setTabAppModal("app");
      setOpenApproveModal(true);
      dispatch(setOpenCampSettings(false));
    }
  }, [openSettingsModal]);
  const showAddSetUpModal = () => {
    setTabAppModal("emSetUp");
    setOpenApproveModal(true);
  };
  //   const showAppSetUpModal = () => {
  //     setTabAppModal('app')
  //   setOpenApproveModal(true);
  // };
  const onCloseAppModal = () => {
    setOpenApproveModal(false);
    dispatch(setOpenCampSettings(false));
    setTabAppModal("app");
  };

  const actionsForTodayCount = totalAutoEmails + totalNoAutoEmails + totalPhones;

  const { id } = useParams();

  console.log(outreachCampaignsList, campaignDisplaying, "asdfjuhbdfs", campRecord);

  return (
    <>
      <ContentCard
        customClassName={"all-actions-card"}
        headerIcon={<SvgIcon type={"allActions"} />}
        cardTitle={
          <Flex>
            <div>
              {" "}
              {[0, 6].includes(new Date().getDay()) ? (
                <>
                  Actions for this week{" "}
                  {
                    !!payingClient && (
                      <Tooltip
                        placement={"top"}
                        title={
                          <>
                            Weekend Tip: <br />
                            Outreach is generally more effective during the work week rather than on the weekends 🏖️🌴
                          </>
                        }
                      >
                        <SvgIcon type={"tooltip"} style={{ marginLeft: 8 }} />
                      </Tooltip>
                    )
                    // only if unlocked, otherwise they can think to unlock Meetz and pay 1/2 days later to strat camp and forget
                  }
                </>
              ) : (
                `All Actions for Today`
              )}
              {/* {!campRecord?.overview?.isActive && <span style={{color:'orange',fontWeight:450,fontSize:'14px',marginLeft:'8px'}}>- campaign inactive</span>} */}
            </div>{" "}
            {!!payingClient &&
              !!campRecord?.overview?.isActive &&
              !!campRecord?.emailDisconnectedData?.length &&
              !!campRecord?.emailDisconnectedData[0]?.emailDisconnected?.length && (
                <>
                  <Tooltip
                    placement={"top"}
                    title={
                      <>
                        <span style={{ fontWeight: 500 }}>Warning:</span> Some prospects were assigned an email address that is now disconnected from your
                        account and now can't proceed in the step. <br />
                        (Individual prospects are assigned 1 of your email addresses to ensure they get emails only from 1 address throughout the flow)
                        <br />
                        <br />
                        Emails That Are Disconnected:
                        {campRecord?.emailDisconnectedData?.map((em) => (
                          <>
                            {" "}
                            <br />
                            <span style={{ fontWeight: 600 }}>{em.emailDisconnected}</span> - {em.amount} prospect{em.amount > 1 && "s"} associated to this
                            email
                          </>
                        ))}
                        <br />
                        <br />
                        <br />
                        <span style={{ cursor: "pointer", textDecoration: "underline", fontWeight: 500 }} onClick={showDrawer}>
                          Reconnect Email
                        </span>
                        - Recommended
                        <br />
                        <br />
                        <span
                          style={{ cursor: "pointer", textDecoration: "underline", color: "orangered", fontWeight: 500 }}
                          onClick={() => {
                            message.info(`Reset`);
                            dispatch(resetCampIdDiscEmails({ campId: campRecord?.overview?.campId }));
                          }}
                        >
                          Reassign email addresses
                        </span>{" "}
                        - Only if you can't reconnect emails
                      </>
                    }
                  >
                    {/* TODOF add blog link so they can read why this happens not critical now */}
                    <SvgIcon type={"warning"} style={{ marginLeft: 8, cursor: "pointer", width: "18px", height: "18px" }} />
                  </Tooltip>
                </>
              )}
          </Flex>
        }
        headerRightContent={
          !loading && payingClient ? (
            <Button
              className={"link plus-btn"}
              onClick={() => {
                dispatch(
                  getFilteredLeadsRst({
                    uploadName: "All",
                    recentlyUpdated: false,
                    includeInCampaign: false,
                    includeContactedAlready: false,
                    page: 1,
                    pageChange: false,
                    selectedCampaignId: null,
                  })
                );
                navigate(`/campaign/${id}/prospects`);
              }}
            >
              Add Prospects
            </Button>
          ) : null
        }
      >
        {
          // !payingClient ? (
          //   <Flex vertical justify={"center"}>
          //     <Flex justify={"center"}>
          //       <img src={manWithSkateboard} alt={"Plan not activated"} width={198} height={200} />
          //     </Flex>
          //     <Typography.Paragraph className={"empty-data"} style={{ textAlign: "center", marginTop: 22 }}>
          //       Campaign is not activated
          //     </Typography.Paragraph>
          //     {/* <Button className={"link"} style={{ marginTop: 8 }} onClick={handleUpdatePlanClick}>
          //       Update Plan
          //     </Button> */}
          //   </Flex>
          // ) :
          actionsForTodayCount && items?.length ? (
            <Collapse items={items} controlActKeys={controlActKeys} setControlActKeys={setControlActKeys} />
          ) : (
            <Flex vertical gap={22} justify={"center"}>
              <Flex justify={"center"}>
                <img src={noActions} alt={"No actions"} width={309} />
              </Flex>
              <Typography.Paragraph className={"empty-data"} style={{ textAlign: "center" }}>
                {items?.length
                  ? `No Actions For Today.${selectedStepNum ? ` (Also step ${selectedStepNum} doesn't need action today 🙂)` : ""}`
                  : 'No steps... add steps by clicking on "Edit Sequence"'}
              </Typography.Paragraph>
            </Flex>
          )
        }
      </ContentCard>
      {/* <SelectEmailModal data={data[0].info} open={openModal} onCancel={onCancel} /> */}
      <ViewAiEmailModal open={!!aiEmailOpen?.pros?.id} prospectId={aiEmailOpen?.pros?.id} isAuto={!!aiEmailOpen?.isAuto} onCancel={() => setAiEmailOpen({})} />
      <MoveProspectsSelectStepModal open={openSelectStepModal} onCancel={onCancel} />

      <CampApproveModal
        tabAppModal={tabAppModal}
        setTabAppModal={setTabAppModal}
        emailStepsOptions={emailStepsOptions}
        open={openApproveModal}
        onCancel={onCloseAppModal}
      />
      {/* TODOF Add the above option to move the prospects from 1 step to the nex */}

      <ManageEmailDrawer open={openDrawer} onCancel={onClsDrawer} campaign={campaignDisplaying?.campaign} />
      {/* <CallSetupModal data={data[1].info} open={openCallSetupModal} onCancel={onCancel} /> */}
    </>
  );
};
