import { Divider, Flex, Typography } from "antd"
import { FC, useState } from "react"
import { TabsSwitch } from "src/components/ui"
import { InfoItem } from "../InfoItem"

export const LinkedInCardContent: FC<{ data: any }> = ({ data }) => {
  const [activeTab, setActiveTab] = useState("LinkedIn Feed")

  return (
    <Flex vertical>
      <TabsSwitch
        firstTab={"LinkedIn Feed"}
        secondTab={"LinkedIn Bio"}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isMediumSize
      />
      {activeTab === "LinkedIn Feed" ? (
        <Flex vertical style={{ height: 328, overflowY: "scroll", marginTop: 16 }}>
          {data.linkedinFeed &&
            data.linkedinFeed.map((item: any, index: number) => {
              return (
                <>
                  <Flex vertical gap={8} key={item.id}>
                    <InfoItem label={"How Long Ago"} value={item.howLongAgo} />
                    <InfoItem label={"Action"} value={item.action} />
                    <InfoItem label={"Person Who Posted This"} value={item.personWhoPosted} />
                    <InfoItem label={"Post Text"} value={item.post} />
                  </Flex>
                  {index !== data.length - 1 && <Divider style={{ margin: "16px 0" }} />}
                </>
              )
            })}
        </Flex>
      ) : (
        <Flex vertical gap={8} style={{ height: 328, overflowY: "scroll", marginTop: 16 }}>
          <InfoItem label={"Title"} value={data.linkedinBio.title} />
          <InfoItem label={"Summary"} value={data.linkedinBio.summary} />
          <InfoItem label={"Location"} value={data.linkedinBio.location} />
          <Divider style={{ margin: "16px 0" }} />
          <Typography.Paragraph style={{ color: "#2E2E2E", fontSize: 12 }}>List Of Job Positions</Typography.Paragraph>
          <InfoItem label={"Company"} value={data.linkedinBio.company} />
          <InfoItem label={"Start Date"} value={data.linkedinBio.startDate} />
          <InfoItem label={"End Date"} value={data.linkedinBio.endDate} />
        </Flex>
      )}
    </Flex>
  )
}
