import { Flex } from "antd"
import { FC } from "react"
import { InfoItem } from "../InfoItem"

export const ContactCardContent: FC<{ data: any }> = ({ data }) => {
  return (
    <Flex vertical gap={16} style={{ height: 328 }}>
      <InfoItem label={"Name"} value={data.contactInfo.name} />
      <InfoItem label={"Job Title"} value={data.contactInfo.jobTitle} />
      <InfoItem label={"Company Name"} value={data.contactInfo.company} />
      <InfoItem label={"Country From"} value={data.contactInfo.country} />
      <InfoItem label={"Their Time Now"} value={data.contactInfo.localTime} />
      <InfoItem label={"Phone Number"} value={data.contactInfo.phoneNumber} />
    </Flex>
  )
}
