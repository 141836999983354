import { Checkbox, CheckboxProps, GetProp, Space } from "antd";
import React, { FC, useEffect, useState } from "react";

type CheckboxValueType = GetProp<typeof Checkbox.Group, "value">[number];

const bareOptions = ["AI Personalized Email", "Templated Emails"]; // TODOF add back in before releasing version with dialer also check on backedn Phone Call
// const bareOptions = ["AI Personalized Email", "Templated Emails", "Phone Call - Power Dialer"];
const autoOptions = ["Automated Booking", "Auto Send Emails Upon Ai Generation"];
const plainPhoneOptions = ["Phone Calls", "Voicemail", "SMS"];
const plainEmailsOptions = ["AI Personalized Email", "Manual Emails"];

export const StepsTypesCheckboxes: FC = ({ setCheckErr, checkedBare, setCheckedBare, automations, setAutomations }) => {
  const [checkedPhoneList, setCheckedPhoneList] = useState<CheckboxValueType[]>([]);
  const [checkedEmailsList, setCheckedEmailsList] = useState<CheckboxValueType[]>([]);

  useEffect(() => {
    setCheckErr(!checkedBare?.length);
  }, [checkedBare]);
  console.log(checkedBare, "checkedBare?.length", bareOptions?.length);
  const checkAllBare = bareOptions?.length === checkedBare?.length;
  const checkAllAuto = autoOptions?.length === automations?.length;
  const checkAllPhone = plainPhoneOptions?.length === checkedPhoneList?.length;
  const bareIndeterminate = checkedBare && checkedBare.length > 0 && checkedBare.length < bareOptions.length;
  const autoIndeterminate = automations && automations.length > 0 && automations.length < autoOptions.length;
  console.log(bareIndeterminate, "bareIndeterminate", checkedBare);
  const phoneIndeterminate = checkedPhoneList && checkedPhoneList.length > 0 && checkedPhoneList.length < plainPhoneOptions.length;

  const checkAllEmails = plainEmailsOptions?.length === checkedEmailsList?.length;
  const emailsIndeterminate = checkedEmailsList && checkedEmailsList.length > 0 && checkedEmailsList.length < plainEmailsOptions.length;

  const onPhoneChange = (list: CheckboxValueType[]) => {
    setCheckedPhoneList(list);
  };

  const onAutoChange = (list: CheckboxValueType[]) => {
    setAutomations(list);
  };
  const onBareChange = (list: CheckboxValueType[]) => {
    setCheckedBare(list);
  };
  const onEmailsChange = (list: CheckboxValueType[]) => {
    setCheckedEmailsList(list);
  };

  const onCheckAllBareChange: CheckboxProps["onChange"] = (e) => {
    setCheckedBare(e.target.checked ? bareOptions : []);
  };
  const onCheckAllAutoChange: CheckboxProps["onChange"] = (e) => {
    setAutomations(e.target.checked ? autoOptions : []);
  };
  const onCheckAllPhoneChange: CheckboxProps["onChange"] = (e) => {
    setCheckedPhoneList(e.target.checked ? plainPhoneOptions : []);
  };

  const onCheckAllEmailsChange: CheckboxProps["onChange"] = (e) => {
    setCheckedEmailsList(e.target.checked ? plainEmailsOptions : []);
  };

  return (
    <Space direction={"vertical"}>
      <Checkbox indeterminate={bareIndeterminate} onChange={onCheckAllBareChange} checked={checkAllBare} style={{ fontWeight: 500 }}>
        Outreach steps:
      </Checkbox>
      <Checkbox.Group
        options={bareOptions}
        value={checkedBare}
        onChange={onBareChange}
        // disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />
      {/* 
      <Checkbox indeterminate={phoneIndeterminate} onChange={onCheckAllPhoneChange} checked={checkAllPhone} style={{fontWeight:500}}>
        Phone:
      </Checkbox>
      <Checkbox.Group
        options={plainPhoneOptions}
        value={checkedPhoneList}
        onChange={onPhoneChange}
        disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />
      <Checkbox indeterminate={emailsIndeterminate} onChange={onCheckAllEmailsChange} checked={checkAllEmails} style={{fontWeight:500}}>
        Emails:
      </Checkbox>
      <Checkbox.Group
        options={plainEmailsOptions}
        value={checkedEmailsList}
        onChange={onEmailsChange}
        disabled={checkedEmailsList?.length===plainEmailsOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      /> */}

      <Checkbox indeterminate={autoIndeterminate} onChange={onCheckAllAutoChange} checked={checkAllAuto} style={{ fontWeight: 500 }}>
        Automations:
      </Checkbox>
      <Checkbox.Group
        options={autoOptions}
        value={automations}
        onChange={onAutoChange}
        // disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />

      {/* Automated Booking: it actually doesnt do anything now, right now this is about scheduling links/laura. next will need to do interest recognition and automatically try to book the meeting this is a large functionality. */}
    </Space>
  );
};
