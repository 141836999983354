import { Button, Col, Flex, Row, Typography } from "antd"
import React, { FC } from "react"
import { ContentCard, Modal } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { browsers, companyProfile, virtualLady } from "src/shared/images"
import "./index.less"
import { CompanyCardContent, ContactCardContent, DialerButtonsFooter, LinkedInCardContent } from "./ui"

type AiGeneratedPersonalModalProps = {
  open: boolean
  onCancel: VoidFunction
  data: any
}

export const AiGeneratedPersonalIntroModal: FC<AiGeneratedPersonalModalProps> = ({ open, onCancel, data }) => {
  const closeModal = () => {
    onCancel()
  }

  return (
    <Modal
      customClassName={"ai-generated-personal-modal"}
      width={970}
      open={open}
      title={"AI Generated Personal Intro Sentence"}
      onCancel={closeModal}
    >
      <Flex vertical>
        <Typography.Paragraph style={{ marginBottom: 28, color: "#757A89" }}>
          Based on your focus on finding solutions for customers and providing valuable information, our Al-powered
          email personalization service could <b>help you</b> efficiently <b>personalize your outreach</b> and provide{" "}
          <b>valuable resources to your prospects</b>, making you an even more valuable partner to them.
        </Typography.Paragraph>
        <Row gutter={24} wrap={false}>
          <Col span={8}>
            <ContentCard
              headerIcon={<SvgIcon type={"likeLinkedIn"} />}
              cardTitle={"LinkedIn Activity"}
              headerRightContent={<Button className={"link"}>Profile</Button>}
            >
              <Flex justify={"center"} align={data ? "unset" : "center"} style={{ height: 382 }}>
                {data ? (
                  <LinkedInCardContent data={data} />
                ) : (
                  <img src={browsers} alt={"browsers with paper plane"} width={111} height={98} />
                )}
              </Flex>
            </ContentCard>
          </Col>
          <Col span={8}>
            <ContentCard headerIcon={<SvgIcon type={"contactInfo"} />} cardTitle={"Contact Information"}>
              <Flex justify={data ? "unset" : "center"} align={data ? "unset" : "center"} style={{ height: 382 }}>
                {data ? (
                  <ContactCardContent data={data} />
                ) : (
                  <Flex vertical justify={"center"} gap={18}>
                    <Flex justify={"center"}>
                      <img src={virtualLady} alt={"lady with VR glasses"} width={174} />
                    </Flex>
                    <Typography.Paragraph className={"loading-contact-info"}>
                      Loading... Gathering information. This may take a few moments.
                    </Typography.Paragraph>
                  </Flex>
                )}
              </Flex>
            </ContentCard>
          </Col>
          <Col span={8}>
            <ContentCard headerIcon={<SvgIcon type={"companyInfo"} />} cardTitle={"Company Information"}>
              <Flex justify={"center"} align={data ? "unset" : "center"} style={{ height: 382 }}>
                {data ? (
                  <CompanyCardContent data={data} />
                ) : (
                  <img src={companyProfile} alt={"company profile on the gadget"} width={126} />
                )}
              </Flex>
            </ContentCard>
          </Col>
        </Row>
      </Flex>
      <DialerButtonsFooter closeModal={closeModal} />
    </Modal>
  )
}
