import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import prospectDataReducer from "./prospect_data";
// import modalsReducer from './modals';
import userReducer from "./user";
// import meetingReducer from './meeting';
// import homeReducer from './home';
import stripeReducer from "./stripe";
// import searchReducer from './search';
import helpersReducer from "./helpers";
// import settingsReducer from './settings';
// import integrationsReducer from './integrations';
// import subscriptionTypeReducer from './subscription-type';
// import humanReducer from './human';
// import aiSalesSettingsReducer from './ai_sales_email_settings';
import calReducer from "./web-calendar";
import outreachReducer from "./outreach-campaigns";
import outreachEmailsReducer from "./outreach-emails";
import outreachPhonesReducer from "./outreach-phones";
// import emailBookSliceReducer from './email_booking';
// import invitationsReducer from './invitations';
import dialerReducer from "./dialer";
import billingReducer from "./billing";
import reactiveLinksReducer from "./reactive-links-settings";

// const middlewares: never[] = [];
// if there is a bug check that the prospectDataSelector has a createSelector(state.(the variable exact spelling you have in the bottom list. For example prospectData))

const rootReducer = combineReducers({
  prospectData: prospectDataReducer,
  // integrations: integrationsReducer,
  helpers: helpersReducer,
  // home: homeReducer,
  // aiSalesSettings: aiSalesSettingsReducer,
  // meeting: meetingReducer,
  // modals: modalsReducer,
  // search: searchReducer,
  // settings: settingsReducer,
  stripe: stripeReducer,
  user: userReducer,
  // human: humanReducer,
  cal: calReducer,
  outreachCampaign: outreachReducer,
  outreachEmails: outreachEmailsReducer,
  outreachPhones: outreachPhonesReducer,
  // subscriptionType: subscriptionTypeReducer,
  // invitations: invitationsReducer,
  reactiveLinks: reactiveLinksReducer,
  // emailBookSlice: emailBookSliceReducer,
  dialer: dialerReducer,
  billing: billingReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "prospectData",
    "outreachCampaign",
    "outreachPhones",
    "outreachEmails",
    // 'dialer',
    // 'meeting',
    // 'home',
    // 'modals',
    // "settings",
    "stripe",
    // 'search',
    // 'integrations',
    "user",
    // 'subscriptionType',
    // 'invitations',
    // 'human',
    // 'aiSalesSettings',
    "reactiveLinks",
    "cal",
    // 'emailBookSlice',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
  devTools: import.meta.env.VITE_NODE_ENV !== "production",
});
export type AppDispatch = typeof store.dispatch;
const persistor = persistStore(store);

export default store;
export { persistor };
