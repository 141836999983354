import { Divider, Flex } from "antd"
import { FC, useState } from "react"
import { Link } from "react-router-dom"
import { TabsSwitch } from "src/components/ui"
import { InfoItem } from "../InfoItem"

export const CompanyCardContent: FC<{ data: any }> = ({ data }) => {
  const [activeTab, setActiveTab] = useState("Company Post")

  return (
    <Flex vertical>
      <TabsSwitch
        firstTab={"Company Post"}
        secondTab={"Company Article"}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isMediumSize
      />
      {activeTab === "Company Post" ? (
        <Flex vertical style={{ height: 328, overflowY: "scroll", marginTop: 16 }}>
          {data.companyPost &&
            data.companyPost.map((item: any, index: number) => {
              return (
                <>
                  <Flex vertical gap={8} key={item.id}>
                    <InfoItem label={"How Long Ago"} value={item.howLongAgo} />
                    <InfoItem label={"Post Text"} value={item.post} />
                  </Flex>
                  {index !== data.length - 1 && <Divider style={{ margin: "16px 0" }} />}
                </>
              )
            })}
        </Flex>
      ) : (
        <Flex vertical style={{ height: 328, overflowY: "scroll", marginTop: 16 }}>
          {data.companyArticle &&
            data.companyArticle.map((item: any, index: number) => {
              return (
                <>
                  <Flex vertical gap={8} key={item.id}>
                    <InfoItem label={"How Long Ago"} value={item.howLongAgo} />
                    <InfoItem label={"Post Text"} value={item.post} />
                    <InfoItem label={"Article In Post"} value={item.article} />
                    <InfoItem label={"Article Subtitle"} value={item.articleSubtitle} />
                    <InfoItem label={"Article Link"} value={<Link to={item.articleLink}>Link</Link>} />
                  </Flex>
                  {index !== data.length - 1 && <Divider style={{ margin: "16px 0" }} />}
                </>
              )
            })}
        </Flex>
      )}
    </Flex>
  )
}
