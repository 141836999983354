import { Button, Col, Row, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, Fragment, useEffect, useState } from "react"
import { PhoneCallsStatus } from "../PhoneCallsStatus"
import { Table, TableHeader } from "src/components/ui"
import { App } from "src/types"
import { phoneCallsData } from "../../info"
import "./index.less"

export const PhoneCallsTable: FC = () => {
const [loading, setLoading] = useState(false)

 

  const columns: ColumnsType<App.PhoneCallsEntity> = [
	{
      title: "Contact name",
      render: record => `${record.firstName} ${record.lastName}`,
      key: "name"
	},
	{
      title: "Last time contacted", 
      dataIndex: "lastTimeContacted",     
      key: "lastTimeContacted"
	},
	{
      title: "Contents",
      render: record => (
		<Col>
          <Typography.Paragraph ellipsis={{ rows: 2 }} style={{width:180}} className={"content"}>{record.contents}</Typography.Paragraph>
          <Row align={"middle"} style={{ gap: 3 }}>
			<Typography.Paragraph className={"company-step"}>{`Step ${record.company.step} in`}</Typography.Paragraph>
			<Button type={"link"} className={"company-link"}>{record.company.name}</Button>
          </Row>
		</Col>
      ),
      key: "contents"
	},
	{
      title: "Total times called",
      dataIndex: "totalTimesCalled",
      key: "totalTimesCalled"
	},
	{
      title: "Total incoming calls",
      dataIndex: "totalIncomingCalls",
      key: "totalIncomingCalls"
	},
	{
      title: "Status",
      render: record => (
		<Row wrap={false}>
          {record?.status?.map((status: App.PhoneCallsStatus) => <PhoneCallsStatus key={status} status={status} />)}
		</Row>
      ),
      key: "status"
	},
  ]
  
  return (
	<Fragment>
      <TableHeader searchPlaceholder={"Search..."}/>
    <Table customClassName={"phone-calls-table"} loading={loading} columns={columns} dataSource={phoneCallsData}/>
	</Fragment>
  )
}