import { Segmented } from "antd"
import { FC } from "react"
import "./index.less"

interface TabsSwitch {
  firstTab: string
  secondTab: string
  thirdTab?: string
  activeTab: string
  setActiveTab: (tab: string) => void
  isMediumSize?: boolean
  className?: string
}

export const TabsSwitch: FC<TabsSwitch> = ({
  activeTab,
  setActiveTab,
  firstTab,
  secondTab,
  thirdTab,
  isMediumSize = false,
  className,
  ...props
}) => {
  return (
    <Segmented
      className={className ? (isMediumSize ? `medium-switcher ${className}` : className) : ""}
      defaultValue={firstTab}
      onChange={value => setActiveTab(value)}
      options={thirdTab ? [firstTab, secondTab, thirdTab] : [firstTab, secondTab]}
      // style={{ fontSize: 12 }}
      {...props}
    />
  )
}
