/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { createSlice, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { initiateAndGetAllUserInfo } from '../user/asyncRequests';
import { addFreeNum, checkAddress, getAddressInformation, listPhoneNums, remNumFromTwillio, updatePhoneSettings } from './asyncRequests';
// import { checkIfProspIsGenerating, doNotSell, downloadProspects, getProspects, searchForAvailProsp, searchProsp } from './asyncRequests';

const outreachPhonesSlice = createSlice({
  name: 'outreachPhones',
  initialState: {
    loading: false,
    loadingNewPhoneNum: false,
    loadingcheckAddress: false,
guessedAddresses:[],
phone_numbers_available:[],
phone_numbers:[],
rangeOfPros:[],
addressComplete:{street:'notloaded'},
phoneNumbersLeft:0,
number_forward_to:'+1',
    successfullUpdateMsg: false as boolean | string,
    infoUpdateMsg: false as boolean | string,
    failedUpdateMsg: false as boolean | string,
  },
  reducers: {

    remOptimisticPhoneNum: (state,action) => {
    const  phoneNumCopy = _.cloneDeep( state.phone_numbers )
   state.phone_numbers = phoneNumCopy?.filter((num)=>num?.id !== action.payload?.phoneId)
    },
     resetOutcome: (state) => {
      state.successfullUpdateMsg = false;
      state.failedUpdateMsg = false;
      state.infoUpdateMsg = false;
    },
     updAdress: (state,action) => {
      state.addressComplete = action.payload;
    },

  },
 extraReducers: (builder) => {builder
  .addCase(initiateAndGetAllUserInfo.fulfilled, (state,action) => {
    state.phone_numbers = action.payload?.phoneNumbersInfo?.phoneNumbers || []
    state.phoneNumbersLeft = action.payload?.phoneNumbersInfo?.phoneCredsLeft||0
    state.number_forward_to = action.payload?.phoneNumbersInfo?.number_forward_to||'+1'
    state.rangeOfPros = action.payload?.phoneNumbersInfo?.rangeOfPros
   })
  .addCase(updatePhoneSettings.fulfilled, (state,action) => {
    state.phone_numbers = action.payload?.phoneNumbersInfo?.phoneNumbers || []
    state.phoneNumbersLeft = action.payload?.phoneNumbersInfo?.phoneCredsLeft||0
    state.number_forward_to = action.payload?.phoneNumbersInfo?.number_forward_to||'+1'
    state.rangeOfPros = action.payload?.phoneNumbersInfo?.rangeOfPros
  state.successfullUpdateMsg='Updated phone settings'
   })
   .addCase(updatePhoneSettings.rejected, (state,action) => {
     state.failedUpdateMsg = 'Failed to update phone settings, please check your internet'
   })
   .addCase(listPhoneNums.fulfilled, (state,action) => {
    state.phone_numbers_available=action.payload?.phone_numbers_available
state.addressComplete=action.payload?.address
    state.loadingNewPhoneNum= false
   })
   .addCase(listPhoneNums.pending, (state,action) => {
  state.loadingNewPhoneNum= true
state.phone_numbers_available=[]
   })
   .addCase(listPhoneNums.rejected, (state,action) => {
     state.phone_numbers_available=[]
    state.loadingNewPhoneNum= false
     state.failedUpdateMsg = 'Failed to get phone numbers'
   })
   .addCase(addFreeNum.fulfilled, (state,action) => {

      state.phone_numbers = action.payload?.phoneNumbersInfo?.phoneNumbers || []
    state.phoneNumbersLeft = action.payload?.phoneNumbersInfo?.phoneCredsLeft||0
    state.number_forward_to = action.payload?.phoneNumbersInfo?.number_forward_to||'+1'
    state.rangeOfPros = action.payload?.phoneNumbersInfo?.rangeOfPros
    state.successfullUpdateMsg='Added your new Meetz phone number! 📱🎉'

   })
   .addCase(addFreeNum.rejected, (state,action) => {state.failedUpdateMsg=`Something went wrong, make sure your street address included a house/office number. Or your internet might not be connected`})
   .addCase(remNumFromTwillio.fulfilled, (state,action) => {

    if(action.payload?.successful) {
      
      state.successfullUpdateMsg='Removed phone number'
      }else{
    state.failedUpdateMsg='Could not remove phone number. Please check your internet'
      
    }
    state.phone_numbers = action.payload?.phoneNumbersInfo?.phoneNumbers || []
    state.phoneNumbersLeft = action.payload?.phoneNumbersInfo?.phoneCredsLeft||0
   })
   .addCase(remNumFromTwillio.rejected, (state) => {
  state.failedUpdateMsg='Could not remove phone number. Please check your internet'

   })
      .addCase(checkAddress.pending, (state) => {
      if (!state.loadingcheckAddress) {
        state.loadingcheckAddress = true;
      }
    },)
   .addCase(checkAddress.fulfilled, (state, action) => {
      if (state.loadingcheckAddress) {
        state.loadingcheckAddress = false;
      }
      state.guessedAddresses = action.payload.places;
    },)
   .addCase(checkAddress.rejected, (state, action) => {
      if (state.loadingcheckAddress) {
        state.loadingcheckAddress = false;
      }
      state.guessedAddresses = [];
    },)
   .addCase(getAddressInformation.rejected, (state, action) => {
    state.failedUpdateMsg =`Not able to get address, please check your internet or manually write it out.`
    },)
   .addCase(getAddressInformation.fulfilled, (state, action) => {
 state.addressComplete = action.payload;
    },)
   
  //     if (!state.loading) {
  //       state.loading = true;
  //     }
  //     state.totalProspectsAvailable=0
  //     state.curProspPages=1
  //     state.totalProspPages=1
  //      state.prospOnCurPage=[]
  //   })
 
}});

const { actions, reducer } = outreachPhonesSlice;
 export const { remOptimisticPhoneNum ,resetOutcome,updAdress} = actions;

export default reducer;

export const outreachPhonesSelector = createSelector(
  (state) => state.outreachPhones,
  (outreachPhones) => outreachPhones
);
