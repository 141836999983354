import { Button, Col, Flex, Popover, Row, Typography } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons } from "src/components/ui"
import { CustomTag } from "src/components/ui/CustomTag"
import { SvgIcon } from "src/shared/icons"
import { CallNotesModal } from "../CallNotesModal"

const phoneButtons = [
  {
    value: "1",
    letters: "",
  },
  {
    value: "2",
    letters: "ABC",
  },
  {
    value: "3",
    letters: "DEF",
  },
  {
    value: "4",
    letters: "GHI",
  },
  {
    value: "5",
    letters: "JKL",
  },
  {
    value: "6",
    letters: "MNO",
  },
  {
    value: "7",
    letters: "PQRS",
  },
  {
    value: "8",
    letters: "TUV",
  },
  {
    value: "9",
    letters: "WXYZ",
  },
  {
    value: "*",
    letters: "",
  },
  {
    value: "0",
    letters: "+",
  },
  {
    value: "#",
    letters: "",
  },
]

export const DialerButtonsFooter: FC<{ closeModal: VoidFunction }> = ({ closeModal }) => {
  const [openPopover, setOpenPopover] = useState(false)
  const [openCallNotesModal, setOpenCallNotesModal] = useState(false)

  const popoverContent = (
    <Row className={"phone-numbers-wrapper"} gutter={[0, 16]}>
      {phoneButtons.map((item, index) => {
        return (
          <Col key={`${item.value}-${index}`} span={8} style={{ textAlign: "center" }}>
            <Flex className={"phone-numbers-button"} vertical align={"center"} gap={4}>
              <Typography.Paragraph className={"phone-number"}>{item.value}</Typography.Paragraph>
              {item.letters ? (
                <Typography.Paragraph className={"phone-letters"}>{item.letters}</Typography.Paragraph>
              ) : null}
            </Flex>
          </Col>
        )
      })}
    </Row>
  )

  const showCallNotesModal = () => {
    setOpenCallNotesModal(true)
  }
  const closeCallNotesModal = () => {
    setOpenCallNotesModal(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen)
  }

  return (
    <>
      <Flex justify={"space-between"} align={"center"} style={{ marginTop: 28 }}>
        <Button className={"link"} onClick={showCallNotesModal}>
          Add notes
        </Button>
        <Row>
          <CustomTag text={"Call Time: 00:28"} color={"#00ADEE"} backgroundColor={"#00ADEE1A"} />
          <Popover
            content={popoverContent}
            trigger={"click"}
            open={openPopover}
            arrow={false}
            style={{ width: 300 }}
            onOpenChange={handleOpenChange}
          >
            <SvgIcon type={"phoneDialer"} onClick={() => setOpenPopover(true)} style={{ cursor: "pointer" }} />
          </Popover>
        </Row>
        <ConfirmButtons width={200} rightButtonTitle={"Call Back"} leftButtonTitle={"End Call"} onCancel={closeModal} />
      </Flex>
      <CallNotesModal open={openCallNotesModal} onCancel={closeCallNotesModal} />
    </>
  )
}
