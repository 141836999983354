import { Flex, Typography } from "antd"
import { FC, ReactNode } from "react"
import "./index.less"

export const InfoItem: FC<{ label: string; value: string | ReactNode }> = ({ label, value }) => {
  return (
    <Flex className={"item-info-wrapper"} vertical gap={6}>
      <Typography.Paragraph className={"item-label-title"}>{label}</Typography.Paragraph>
      <Typography.Paragraph
        className={"item-value-title"}
        ellipsis={{ rows: 2, expandable: true, symbol: " See more" }}
      >
        {value ? value : "-"}
      </Typography.Paragraph>
    </Flex>
  )
}
