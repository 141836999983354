/* eslint-disable no-unused-vars */
 
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiHandler } from '../helpers/apiHandler';

export const createDialerEmailCampFollowUp = createAsyncThunk('dialer/createDialerEmailCampFollowUp', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/createDialerEmailCampFollowUp',
    method: 'post',
    body,
  });
  return res;
});

export const updateEmailTemplate = createAsyncThunk('dialer/updateEmailTemplate', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/updateEmailTemplate',
    method: 'post',
    body,
  });
  return res;
});

export const getAddressInformation = createAsyncThunk('dialer/getAddressInformation', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/getAddressInformation',
    method: 'post',
    body,
  });
  console.log(res, 'addressComplete3');
  return res;
});
export const checkAddress = createAsyncThunk('dialer/checkAddress', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/checkAddress',
    method: 'post',
    body,
  });
  return res;
});

export const saveEmailTemplate = createAsyncThunk('dialer/saveEmailTemplate', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/saveEmailTemplate',
    method: 'post',
    body,
  });
  return res;
});

export const saveOrUpdateEmailTemplate = createAsyncThunk('dialer/saveOrUpdateEmailTemplate', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/saveOrUpdateEmailTemplate',
    method: 'get',
  });
  return res;
});

export const whoIsActiveContact = createAsyncThunk('dialer/whoIsActiveContact', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/whoIsActiveContact',
    method: 'get',
  });
  return res;
});

export const sawNotifications = createAsyncThunk('dialer/sawNotifications', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/sawNotifications',
    method: 'post',
    body,
  });
  return res;
});

export const updateCallNotes = createAsyncThunk('dialer/updateCallNotes', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/updateCallNotes',
    method: 'post',
    body,
  });
  return res;
});

export const whoIsActiveContactLinkedIn = createAsyncThunk('dialer/whoIsActiveContactLinkedIn', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/whoIsActiveContactLinkedIn',
    method: 'get',
  });
  return res;
});

export const toggleIntroLine = createAsyncThunk('dialer/toggleIntroLine', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/toggleIntroLine',
    method: 'post',
    body,
  });
  return res;
});

export const whoIsActiveContactCompany = createAsyncThunk('dialer/whoIsActiveContactCompany', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/whoIsActiveContactCompany',
    method: 'get',
  });
  return res;
});

export const callMultiplePeople = createAsyncThunk('dialer/callMultiplePeople', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/callMultiplePeople',
    method: 'post',
    body,
  });
  return res;
});

export const loadPowerDialer = createAsyncThunk('dialer/loadPowerDialer', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/loadPowerDialer',
    method: 'post',
    body,
  });
  return res;
});

export const loadPowerDialerFirstTime = createAsyncThunk('dialer/getPowerDialerIds', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/getPowerDialerIds',
    method: 'post',
    body,
  });
  return res;
});

export const changeInboundPage = createAsyncThunk('dialer/changeInboundPage', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/changeInboundPage',
    method: 'post',
    body,
  });
  return res;
});
export const changePage = createAsyncThunk('dialer/changePage', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/changePage',
    method: 'post',
    body,
  });
  return res;
});
export const listPhoneNums = createAsyncThunk('dialer/listPhoneNums', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/listPhoneNums',
    method: 'post',
    body,
  });
  return res;
});

export const remNumFromTwillio = createAsyncThunk('dialer/getDialer', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/remNumFromTwillio',
    method: 'delete',
    body,
  });
  return res;
});
export const changeDialerSettings = createAsyncThunk('dialer/getDialer', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/changeDialerSettings',
    method: 'put',
    body,
  });
  return res;
});

export const lookupProspect = createAsyncThunk('dialer/lookupProspect', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/lookupProspect',
    method: 'post',
    body,
  });

  return res;
});

export const heartbeatToShowOnlineAndGetStatus = createAsyncThunk('dialer/heartbeatToShowOnlineAndGetStatus', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/heartbeatToShowOnlineAndGetStatus',
    method: 'post',
  });

  return res;
});

export const stopMultiDialingActive = createAsyncThunk('dialer/stopMultiDialingActive', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/multiModeCallTurnOff',
    method: 'post',
  });

  return res;
});

export const getTwilioVoiceToken = createAsyncThunk('dialer/getTwilioVoiceToken', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/getTwilioVoiceToken',
    method: 'get',
  });

  return res;
});
export const getSpotify = createAsyncThunk('dialer/getSpotify', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/spotify/getSpot',
    method: 'get',
  });

  return res;
});

export const updateSpotify = createAsyncThunk('dialer/getSpotify', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/spotify/update',
    method: 'post',
    body,
  });

  return res;
});

export const addOrReplaceSpotify = createAsyncThunk('dialer/getSpotify', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/spotify/addSpotifyOrReplace',
    method: 'post',
    body,
  });

  return res;
});

export const addDialerCampaignBe = createAsyncThunk('dialer/addDialerCampaignBe', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/addDialerCampaignBe',
    method: 'post',
    body,
  });

  return res;
});

export const updateDialerCampaignProp = createAsyncThunk('dialer/updateDialerCampaignProp', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/updateDialerCampaignProp',
    method: 'post',
    body,
  });

  return res;
});

export const getFocusedCampInfo = createAsyncThunk('dialer/getFocusedCampInfo', async (id,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/getFocusedCampInfo',
    method: 'post',
    body: { id },
  });

  return res;
});
export const getDialer = createAsyncThunk('dialer/getDialer', async (_,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/getDialer',
    method: 'get',
  });

  return res;
});

export const delDialerCampaignBe = createAsyncThunk('dialer/getDialer', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/delDialerCampaignBe',
    method: 'delete',
    body,
  });

  return res;
});

export const frwdNumChange = createAsyncThunk('dialer/frwdNumChange', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/dialer/changeForwardNumber',
    method: 'post',
    body,
  });

  return res;
});
