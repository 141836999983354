import { Modal as AntModal, ModalProps } from "antd"
import { FC, PropsWithChildren } from "react"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

interface CustomModalProps extends ModalProps {
  customClassName?: string
  hasCloseIcon?: boolean
}

export const Modal: FC<PropsWithChildren<CustomModalProps>> = ({ customClassName, hasCloseIcon = true, ...props }) => {
  const defaultProps = {
    styles: {
      mask: { backgroundColor: "#EDEFF399" },
    },
    maskClosable: hasCloseIcon,
    footer: null,
    closeIcon: hasCloseIcon ? <SvgIcon type={"closeModal"} /> : null,
    className: `modal-wrapper ${customClassName}`,
    rootClassName: "modal-root",
    forceRender: true,
    centered: true,
  }

  return (
    <AntModal {...defaultProps} {...props}>
      {props.children}
    </AntModal>
  )
}
