import { Form, Radio, Row, Space, Typography } from "antd"
import PhoneInput from "antd-phone-input"
import React, { FC, useState } from "react"
import { DialerButtonsFooter } from "src/components/pages/campaigns/CampaignDetails/ui/ParallelDialer/ui/AiGeneratedPersonalIntroModal/ui"
import { ConfirmButtons, CustomSelect, Modal, StatusTag, TableHeader } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { SendSmsModal } from "./ui/SendSmsModal"
import "./index.less"

type DialerModalProps = {
  open: boolean
  onCancel: VoidFunction
}

const dialerData = [
  {
    id: "1",
    name: "Tom Smith",
    phones: {
      m: "(+1) 435-345-1234",
      d: "(+1) 235-555-1234",
      c: "(+1) 425-665-1884",
    },
  },
]

const numbersToCallFrom = [
  {
    phoneNumber: "(555) 345-4554",
    status: true,
  },
  {
    phoneNumber: "(234) 555-4545",
    status: false,
  },
]

export const getDialerPhoneOptions = (data: any) => {
  return [
    {
      value: data.m,
      label: `Mobile phone ${data.m}`,
    },
    {
      value: data.d,
      label: `Direct phone ${data.d}`,
    },
    {
      value: data.c,
      label: `Company phone ${data.c}`,
    },
  ]
}

export const DialerModal: FC<DialerModalProps> = ({ open, onCancel }) => {
  const [isCalling, setIsCalling] = useState(false)
  const [openSendSmsModal, setOpenSendSmsModal] = useState(false)
  const [form] = Form.useForm()
  const closeModal = () => {
    setIsCalling(false)
    form.resetFields()
    onCancel()
  }

  const showSendSmsModal = () => {
    setOpenSendSmsModal(true)
  }
  const closeSendSmsModal = () => {
    setOpenSendSmsModal(false)
  }

  const handleFormSubmit = () => {
    setIsCalling(true)
  }

  return (
    <Modal customClassName={"dialer-modal"} width={530} open={open} title={"Dialer"} onCancel={closeModal}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Typography.Title level={4} style={{ marginBottom: 16 }}>
          Select the recipient of the call
        </Typography.Title>
        <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginBottom: 16 }}>
          Search Your Leads
        </Typography.Paragraph>
        <TableHeader searchPlaceholder={"Search Leads..."} hasMaxWidth={false} />
        {dialerData && (
          <Form.Item name={"lead"}>
            <Radio.Group style={{ width: "100%" }}>
              {dialerData.map((lead, index) => {
                return (
                  <Space key={`${index}-${lead.id}`} direction={"vertical"}>
                    <Row align={"middle"} wrap={false}>
                      <Radio value={lead.id}>{lead.name}</Radio>
                      <SvgIcon type={"dividerDot"} style={{ marginRight: 8 }} />
                      <CustomSelect
                        defaultValue={lead.phones.d}
                        className={"phone-select"}
                        options={getDialerPhoneOptions(lead.phones)}
                        popupClassName={"phone-select-dropdown"}
                      />
                    </Row>
                  </Space>
                )
              })}
            </Radio.Group>
          </Form.Item>
        )}
        <Typography.Paragraph style={{ fontSize: 12, marginBottom: 16 }}>Or</Typography.Paragraph>
        <Form.Item className={"phone-number-input"} name={"phoneNumber"} label={"Input Number"}>
          <PhoneInput className={"phone-flags-select"} disabled={!!dialerData} enableSearch />
        </Form.Item>
        {numbersToCallFrom && (
          <Form.Item name={"numberToCallFrom"} label={"Select the number to call from"}>
            <Radio.Group style={{ width: "100%" }}>
              {numbersToCallFrom.map(phone => {
                return (
                  <Space key={phone.phoneNumber} direction={"vertical"} size={11}>
                    <Row justify={"space-between"} align={"middle"} wrap={false}>
                      <Radio value={phone.phoneNumber}>{phone.phoneNumber}</Radio>
                      {phone.status && <StatusTag value={"Used before"} color={"success"} style={{ margin: 0 }} />}
                    </Row>
                  </Space>
                )
              })}
            </Radio.Group>
          </Form.Item>
        )}
        {isCalling ? (
          <DialerButtonsFooter closeModal={closeModal} />
        ) : (
          <ConfirmButtons rightButtonTitle={"Call"} leftButtonTitle={"Send SMS"} onCancel={showSendSmsModal} />
        )}
      </Form>
      <SendSmsModal open={openSendSmsModal} onCancel={closeSendSmsModal} data={dialerData[0]} />
    </Modal>
  )
}
