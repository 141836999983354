import { Button, Flex, Row, Space, Typography } from "antd"
import React, { FC, useEffect, useState } from "react"
import { Loader } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { aiGeneratedPersonalData } from "../../../campaignsData"
import "./index.less"
import { DialerPopupInfo } from "./ui"
import { AiGeneratedPersonalIntroModal } from "./ui/AiGeneratedPersonalIntroModal"

interface ParallelDialerModalProps {
  onCancel?: VoidFunction
}

export const ParallelDialerModal: FC<ParallelDialerModalProps> = ({ onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [dialing, setDialing] = useState(false)
  const [paused, setPaused] = useState(false)
  const [noMinutes, setNoMinutes] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [openAiGeneratedModal, setOpenAiGeneratedModal] = useState(false)

  useEffect(() => {
    const performDialing = async () => {
      setLoading(true)
      setDialing(false)
      await new Promise(resolve => setTimeout(resolve, 4000))
      setLoading(false)
      setDialing(true)
      await new Promise(resolve => setTimeout(resolve, 4000))
      setDialing(false)
      setNoMinutes(true)
      await new Promise(resolve => setTimeout(resolve, 4000))
      setNoMinutes(false)
      setIsCompleted(true)
      await new Promise(resolve => setTimeout(resolve, 4000))
      setIsCompleted(false)
      setOpenAiGeneratedModal(true)
    }

    performDialing()
  }, [])

  const closeModal = () => {
    onCancel && onCancel()
    setOpenAiGeneratedModal(false)
  }

  const togglePause = () => {
    setPaused(prevPaused => !prevPaused)
    setDialing(prevDialing => !prevDialing)
  }

  return (
    <>
      {!openAiGeneratedModal && (
        <Flex className={"parallel-dialer-wrapper"} vertical style={{ height: isCompleted ? 324 : 264 }}>
          <Row justify={"space-between"} style={{ paddingBottom: 28 }}>
            <Typography.Title level={3}>Phone Dialer</Typography.Title>
            <SvgIcon type={"close"} onClick={closeModal} style={{ cursor: "pointer" }} />
          </Row>
          {loading && <Loader tip={"Getting Ready"} style={{ padding: "41px 0" }} />}
          {dialing && <Loader tip={"Dialing"} style={{ padding: "41px 0" }} />}
          {paused && <DialerPopupInfo iconType={"pauseOutline"} bottomText={"Paused"} />}
          {noMinutes && <DialerPopupInfo iconType={"battery"} bottomText={"No dialing minutes left"} />}
          {isCompleted && <DialerPopupInfo iconType={"checked"} bottomText={"Completed"} />}
          {!loading && (
            <Row
              justify={"space-between"}
              align={"middle"}
              wrap={false}
              style={{ paddingTop: paused || noMinutes || isCompleted ? 40 : 110 }}
            >
              <Space direction={"vertical"} size={4}>
                <Typography.Paragraph>{`Called: ${isCompleted ? "500" : "1"}`}</Typography.Paragraph>
                <Typography.Paragraph>{`Out of: ${isCompleted ? "0" : "499"}`}</Typography.Paragraph>
              </Space>
              {!isCompleted && (
                <Button className={"primary"} onClick={!noMinutes ? togglePause : () => console.log("Buy minutes")}>
                  {paused ? "Start" : noMinutes ? "Buy minutes" : "Pause"}
                </Button>
              )}
            </Row>
          )}
          {isCompleted && (
            <Typography.Paragraph className={"complete-comment"}>
              *The system will automatically connect to the dialing environment after 10 minutes.
            </Typography.Paragraph>
          )}
        </Flex>
      )}
      <AiGeneratedPersonalIntroModal open={openAiGeneratedModal} onCancel={closeModal} data={aiGeneratedPersonalData} />
    </>
  )
}
