import { Form, Input, Radio, Space } from "antd"
import React, { FC } from "react"
import { ConfirmButtons, CustomSelect, Modal } from "src/components/ui"

type CallNotesModalProps = {
  open: boolean
  onCancel: VoidFunction
}

const callOutcomeOptions = [
  {
    label: "interested",
    value: "interested",
  },
  {
    label: "Not Interested",
    value: "Not Interested",
  },
  {
    label: "No Answer",
    value: "No Answer",
  },
  {
    label: "Follow Up",
    value: "Follow Up",
  },
  {
    label: "Voicemail",
    value: "Voicemail",
  },
]

export const CallNotesModal: FC<CallNotesModalProps> = ({ open, onCancel }) => {
  const [form] = Form.useForm()
  const closeModal = () => {
    form.resetFields()
    onCancel()
  }
  const handleFormSubmit = () => {
    console.log(" test")
    onCancel()
    form.resetFields()
  }

  return (
    <Modal width={530} open={open} title={"Call Notes"} onCancel={closeModal}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Form.Item name={"callOutcome"} label={"Call Outcome"} initialValue={"Interested"}>
          <CustomSelect options={callOutcomeOptions} />
        </Form.Item>
        <Form.Item name={"remove"} label={"Remove from campaigns (Do not call)"}>
          <Radio.Group>
            <Space direction={"vertical"}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={"notes"} label={"Call Notes"}>
          <Input.TextArea placeholder={"Enter Text"} />
        </Form.Item>
        <ConfirmButtons rightButtonTitle={"Save and Continue"} leftButtonTitle={"Back"} onCancel={closeModal} />
      </Form>
    </Modal>
  )
}
